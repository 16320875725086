module.exports={
    "projects": [{
        "title": "EAKTR",
        "slug": "eaktr",
        "context": "Free time",
        "date": "2017",
        "descr": "This portfolio has been crafted for Elias Akentour, an experienced web designer. It mainly relies on the use of Pixi.js. <br> One of my first steps into the world of 2D WebGL.",
        "technos": "WebGL, Pixi.js",
        "awards": "HM AWWWARDS",
        "media": "eaktr",
        "imgs": ["eaktr.mp4", "eaktr-1.jpg", "eaktr-2.jpg", "eaktr-3.jpg", "eaktr-4.jpg", "eaktr-5.jpg"],
        "link": "http://eliasakentour.com/",
        "color": "#d01010",
        "colorName": "red",
        "postProc": true
    },{
        "title": "BMW Motorshow",
        "slug": "bmw-motorshow",
        "context": "84.Paris",
        "date": "mid 2016",
        "descr": "360 WebGL immersive experience letting the user dive into the BMW booth at the Paris Motorshow. <br> In charge of the full desktop website, I used Three.js for the 360 views and the 3D map.",
        "technos": "WebGL, Three.js",
        "awards": "SOTD FWA, SOTD AWWWARDS",
        "media": "bmw",
        "imgs": ["bmw.mp4", "bmw-1.jpg", "bmw-2.jpg", "bmw-3.jpg", "bmw-4.jpg", "bmw-5.jpg", "bmw-6.jpg", "bmw-7.jpg", "bmw-8.jpg"],
        "link": "http://mondialautomobile.bmw.fr/",
        "color": "#1196f1",
        "colorName": "blue",
        "postProc": true
    },{
        "title": "Nokia Health",
        "slug": "nokia-health",
        "context": "84.Paris",
        "date": "end 2016",
        "descr": "Website for Nokia Health users to show their data during the past year. I used canvas for the animations. ",
        "technos": "Canvas",
        "awards": "",
        "media": "nokia",
        "imgs": ["nokia.mp4", "nokia-1.jpg", "nokia-2.jpg", "nokia-3.jpg", "nokia-4.jpg", "nokia-5.jpg", "nokia-6.jpg"],
        "link": "https://yearreview.health.nokia.com/",
        "color": "#EF489C",
        "colorName": "pink"
    },{
        "title": "The Forest",
        "slug": "the-forest",
        "context": "UNIT 9",
        "date": "end 2015",
        "descr": "My first Three.js and VR project. A scary 360 experiment in virtual reality built for the promotionnal campaign of the movie The Forest. <br> I worked on the VR mobile part of the website.",
        "technos": "WebGL, Three.js, VR",
        "awards": "SOTD FWA, SOTD FWA Mobile",
        "media": "forest",
        "imgs": ["forest.mp4", "forest-1.jpg", "forest-2.jpg", "forest-3.jpg", "forest-4.jpg", "forest-5.jpg"],
        "link": "http://thesuicideforest.com/",
        "color": "#1FB04C",
        "colorName": "green",
        "asteroidsData":[
            { "x": -50, "y": -10, "z": 80, "model": 5 },
            { "x": -50, "y": 5, "z": 10 },
            { "x": -30, "y": -60, "z": -20, "model": 5 },
            { "x": -10, "y": 40, "z": -40 },
            { "x": -60, "y": 50, "z": -40, "model": 2 },
            { "x": 0, "y": -40, "z": -60, "model": 2 },
            { "x": 30, "y": 20, "z": 60, "model": 5 },
            { "x": 20, "y": -20, "z": -30 },
            { "x": 50, "y": -40, "z": 30, "model": 2 },
            { "x": 40, "y": 20, "z": -80, "model": 2 }
        ]
    }],
    "about": {
        "content" : "",
        "tw": "https://twitter.com/RobinPayot",
        "gh": "https://github.com/Robpayot",
        "ld": "https://fr.linkedin.com/in/robin-payot-71951748",
        "projects": [{
            "title" : "Raindrops",
            "descr": "Little Pixi.js experiment about drops.",
            "link": "https://robpayot.github.io/raindrops/dist/"
        },{
            "title" : "2D wave generator",
            "descr": "Small canvas experiment creating and animate 2D waves.",
            "link": "https://codepen.io/Robpayot/pen/vpKqMp"
        },{
            "title" : "Video alpha mask",
            "descr": "Mask applied on text using a video alpha in canvas.",
            "link": "https://robpayot.github.io/video-alpha-mask/"
        },{
            "title" : "Action contre la faim",
            "descr": "NGO website made at 84.Paris.",
            "link": "http://www.actioncontrelafaim.org/"
        }]
    }
}

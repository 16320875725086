module.exports={
  "_args": [
    [
      "p2@0.7.1",
      "/Applications/MAMP/htdocs/portfolio"
    ]
  ],
  "_development": true,
  "_from": "p2@0.7.1",
  "_id": "p2@0.7.1",
  "_inBundle": false,
  "_integrity": "sha1-JfJHTZvDptMUCh2iamfJ4RislUM=",
  "_location": "/p2",
  "_phantomChildren": {},
  "_requested": {
    "type": "version",
    "registry": true,
    "raw": "p2@0.7.1",
    "name": "p2",
    "escapedName": "p2",
    "rawSpec": "0.7.1",
    "saveSpec": null,
    "fetchSpec": "0.7.1"
  },
  "_requiredBy": [
    "#DEV:/"
  ],
  "_resolved": "https://registry.npmjs.org/p2/-/p2-0.7.1.tgz",
  "_spec": "0.7.1",
  "_where": "/Applications/MAMP/htdocs/portfolio",
  "author": {
    "name": "Stefan Hedman",
    "email": "schteppe@gmail.com",
    "url": "http://steffe.se"
  },
  "bugs": {
    "url": "https://github.com/schteppe/p2.js/issues"
  },
  "dependencies": {
    "poly-decomp": "0.1.1"
  },
  "description": "A JavaScript 2D physics engine.",
  "devDependencies": {
    "grunt": "^0.4.5",
    "grunt-browserify": "~2.0.1",
    "grunt-contrib-concat": "^0.4.0",
    "grunt-contrib-jshint": "^0.11.2",
    "grunt-contrib-nodeunit": "^0.4.1",
    "grunt-contrib-uglify": "~0.4.0",
    "grunt-contrib-watch": "~0.5.0"
  },
  "engines": {
    "node": "*"
  },
  "homepage": "https://github.com/schteppe/p2.js#readme",
  "keywords": [
    "p2.js",
    "p2",
    "physics",
    "engine",
    "2d"
  ],
  "licenses": [
    {
      "type": "MIT"
    }
  ],
  "main": "./src/p2.js",
  "name": "p2",
  "repository": {
    "type": "git",
    "url": "git+https://github.com/schteppe/p2.js.git"
  },
  "version": "0.7.1"
}
